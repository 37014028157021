@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap);
body, .MuiInputBase-root {
    font-family: 'Open Sans', sans-serif !important;
    margin: 0 auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h1 {
    font-size: 2rem !important;
    font-weight: 800 !important;
}

h2 {
    font-weight: 700 !important;
}

h3 {
    font-weight: 700 !important;
}

h4 {
    font-size: 1.2rem !important;
    font-weight: 700 !important;
}

h5 {
    font-size: 1.1rem !important;
    font-weight: 800 !important;
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: center;
    font-size: 17px;
}

a {
    color: #90AB43
}

section, .container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 17px;
    max-width: 800px;
}

section#home {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: center;
}

section.page {
    margin: 20px auto;
    padding: 0 8px;
}

.partImg {
    max-height: 80px;
    float: right;
}

.backToTop {
    font-size: 12px;
    width: 100%;
    border-bottom: 1px solid rgba(32, 32, 32, 1);
    cursor: pointer;
    margin-top: 100px;
}

.block {
    margin: 70px auto;
}

.bigBtn {
    background-color: #202020;
    border: 0 none;
    border-radius: 14px;
    padding: 10px 40px;
    color: #fff;
    font-weight: 800;
}

#applyNow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #202020;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 100%;
    min-height: 300px;
    width: 100%;
    color: #fff;
    font-weight: 800;
    text-align: center;
    vertical-align: middle;
    text-shadow: -1px 2px 2px rgba(55, 55, 55, 1);
    font-size: 30px;
}

#applyNow:hover {
    text-shadow: -1px 2px 3px rgba(155, 155, 155, .4);
}

#applyNow small {
    color: #fff;
}

dl {
    display: flex;
    flex-flow: row wrap;
    border: solid #333;
    border-width: 1px 1px 0 0;
    font-size: 18px;
}

dt {
    flex-basis: 20%;
    min-width: 100px;
    padding: 10px 3px 15px 8px;
    background: #333;
    text-align: left;
    color: #fff;
    font-size: 18px;
    line-height: 19px;
}

dd {
    flex-basis: 70%;
    flex-grow: 1;
    margin: 0;
    padding: 5px 4px 4px 4px;
    border-bottom: 1px solid #333;
}

.fullBg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height:100%;
    position: absolute;
    top:0;
    left:0;
}

.fullImg {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    display: block;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    transition: opacity 2.5s ease;
    opacity: 0;
}

.fullImg.active {
    opacity: .2;
}

.sqCta {
    display: inline-block;
    text-align: center;
    min-height: 100px;
    height: 25vh;
    width: 100%;
    font-weight: 800;
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    text-shadow: -1px 2px 2px rgba(32, 32, 32, .25);
    padding-top: 10px;
    margin-top: 10px;
}

@media (min-width: 768px) {
    .sqCta {
        height: 33vh;
    }
}

.sqCta img {
    width: 100%;
}


.sqCta:hover {
    color: #fff;
    text-shadow: -1px 2px 3px rgba(32, 32, 32, 1);
    text-decoration: none;
}

.ctaTech {
    background-color: #90ab43; /* Old browsers */
}

.idea {
    margin: 40px auto;
}

.inlineList {
    margin: 0;
    padding: 0;
}

.inlineList span, .inlineList li {
    list-style: decimal;
    margin-right: 10px;
    display: inline-block;
}

.MuiGrid-item {
    position: relative;
}

.reviewTag {
    position: absolute;
    font-weight: 600;
    text-shadow: '0px 1px 1px #848484';
    bottom: 4px;
    left: 4px;
    padding: 3px 6px;
    background: rgba(255, 255, 255, .8);
}

.isRequired {
    color: red;
    margin: 0 0 0 2px;
}


.editor {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 16px;
    border-radius: 2px;
    margin-bottom: 2em;
    box-shadow: inset 0px 1px 8px -3px #ABABAB;
    background: #fefefe;
}

.editor :global(.public-DraftEditor-content) {
    min-height: 140px;
}

.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 30px;
}

.editor {
    display: block;
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 10px 10px 0 10px;
    border-radius: 5px;
    box-shadow: inset 0px 1px 8px -3px #ABABAB;
    background: #fff;
}

.DraftEditor-root {
    min-height: 120px;
}

.editor :global(.public-DraftEditor-content) {
    display: block;
}

.headlineButtonWrapper, .draftJsToolbar__buttonWrapper__1Dmqh, .toolBtn {
    display: inline-block;
    background: #fff;
    margin-right: 3px
}

.headlineButtonWrapper button,
.draftJsToolbar__buttonWrapper__1Dmqh button,
.toolBtn button {
    border: 1px solid #ccc;
    background: #fff;
    line-height: 26px;
}

.headlineButton {
    background: #fbfbfb;
    color: #888;
    font-size: 18px;
    border: 0;
    padding-top: 6px;
    vertical-align: bottom;
    height: 36px;
    width: 36px;
    display: inline-block;
}

.headlineButton:hover,
.headlineButton:focus {
    background: #f3f3f3;
}

.editorToolbar {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

